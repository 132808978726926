
<template>
  <div id="app">
    <el-container>
      <el-header>OKX Connect</el-header>
      <el-main>
        <!-- 钱包信息 -->
        <el-card class="wallet-info">
          <template #header>
            <div>钱包信息</div>
          </template>
          <el-row class="info-row">
            <el-col :span="24">
              <strong>Wallet Address:</strong> {{ getWalletAddress }}
            </el-col>
          </el-row>

          <el-row class="info-row">
            <el-col :span="24">
              <strong>当前状态 :</strong> {{ currentStatus }}
            </el-col>
          </el-row>

<!--          <el-row class="info-row">-->
<!--            <el-col :span="24">-->
<!--              <strong>Universal Link:</strong> {{ updateUniLink }}-->
<!--            </el-col>-->
<!--          </el-row>-->
        </el-card>

        <!-- 连接钱包 -->
        <el-card class="wallet-actions">
          <template #header>
            <div>钱包操作</div>
          </template>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-input v-model="testProofPayload" placeholder="proofPayload"></el-input>
            </el-col>
          </el-row>
          <el-row class="info-row">
            <el-col :span="24">
              <el-checkbox v-model=isChecked @change="handleChange"> 自动重连</el-checkbox>
            </el-col>
          </el-row>
          <el-row class="info-row" v-if="showVerifyRow">
            <el-col :span="24" >
              <strong>签名验证:</strong> {{ ProofPayloadVerify }}
            </el-col>
          </el-row>
          <el-row :gutter="12" class="button-row">
            <el-col :span="24">
              <el-button type="primary" @click="connectBtnClicked" icon="el-icon-connection">{{ connectStatusStr }}</el-button>
            </el-col>
          </el-row>

          <teleport to="body">
            <div v-if="showModal" class="modal-overlay" @click="closeModal">
              <div class="modal-content" @click.stop>
                <button class="close-button" @click="closeModal">&times;</button>
                <h2>扫描二维码</h2>
                <qrcode-vue :value="qrValue" :size="200" level="H" />
              </div>
            </div>
          </teleport>

          <el-row :gutter="12" class="button-row">
            <el-col :span="24">
              <el-button type="warning" @click="resotreConnectAction" icon="el-icon-refresh-right">尝试重连</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="12"  class="button-row">
            <el-col :span="24">
              <el-button type="danger" @click="disconnectAction" icon="el-icon-switch-button" :disabled="walletAddress.length == 0">{{ disconnectStatusStr }}</el-button>
            </el-col>
          </el-row>
        </el-card>

        <!-- 发送交易 -->
        <el-card class="transaction">
          <template #header>
            <div>交易</div>
          </template>

          <el-row :gutter="12">
            <el-col  :span="8">
              <el-button type="primary" @click="mockSwap()">swap</el-button>
            </el-col>
            <el-col  :span="8">
              <el-button type="success" @click="mockTransaction()">转账</el-button>
            </el-col>
            <el-col  :span="8">
              <el-button type="warning" @click="mockTransactionNFT()">转移NFT</el-button>
            </el-col>
          </el-row>

          <el-col :xs="24" :sm="12">
            <div class="input-label">交易数据:</div>
            <el-input v-model="testTransaction" placeholder="sendTransactions"></el-input>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="input-label">签名结果 :</div>
            <el-input v-model="testboc" placeholder="boc"></el-input>
          </el-col>
          <el-row :gutter="12" class="button-row">
            <el-col :span="24">
              <el-button type="primary" @click="sendTransaction()">{{ testTransactionStr }}</el-button>
            </el-col>
          </el-row>
        </el-card>

        <!-- 本地加解密KeyPair -->
        <!-- <el-card class="keypair-info">
          <template #header>
            <div>本地加解密KeyPair</div>
          </template>
          <el-row :gutter="12">
            <el-col :xs="24" :sm="12">
              <div class="input-label">PubKey:</div>
              <el-input v-model="localPubKey" placeholder="PublicKey" disabled></el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <div class="input-label">SecKey:</div>
              <el-input v-model="localSecKey" placeholder="SecretKey" disabled></el-input>
            </el-col>
          </el-row>
        </el-card> -->

        <!-- 清理缓存 -->
        <!-- <el-card class="clear-cache">
          <el-row>
            <el-col :span="24">
              <el-button type="danger" @click="clearCacheKeyPair()" icon="el-icon-delete">清理本地缓存</el-button>
            </el-col>
          </el-row>
        </el-card> -->
      </el-main>
    </el-container>
  </div>
</template>

<style scoped>
/* 重置全局边距和内边距 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* 确保 app 容器占满整个视口 */
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto; /* 居中 */
  max-width: 600px; /* 设置最大宽度 */
  padding: 0 20px; /* 添加一些左右内边距 */
}

/* 调整 el-container 样式 */
.el-container {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* 调整 el-header 样式 */
.el-header {
  background-color: #333333;
  color: white;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* 调整 el-main 样式 */
.el-main {
  padding: 70px 0 20px; /* 增加顶部内边距以避免被固定header遮挡 */
  flex-grow: 1;
}

.el-card {
  margin-bottom: 20px;
}

.info-row {
  margin-bottom: 10px;
}

.input-label {
  margin-bottom: 5px;
  text-align: left;
}

.el-input {
  margin-bottom: 10px;
}

/* 通用按钮样式 */
.wallet-actions .el-button,
.transaction .el-button,
.clear-cache .el-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.wallet-actions .el-button span,
.transaction .el-button span,
.clear-cache .el-button span {
  margin: 0 auto;
}

/* 按钮行间距 */
.wallet-actions .button-row,
.transaction .button-row {
  margin-bottom: 8px;
}

.wallet-actions .button-row:last-child,
.transaction .button-row:last-child {
  margin-bottom: 0;
}

/* 调整图标和文字的对齐 */
.wallet-actions .el-button [class^="el-icon-"],
.transaction .el-button [class^="el-icon-"],
.clear-cache .el-button [class^="el-icon-"] {
  margin-right: 5px;
  vertical-align: middle;
}

/* 新增：调整发送交易按钮与输入框的间距 */
.transaction .el-row:not(:last-child) {
  margin-bottom: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  max-width: 90%;
  width: 300px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
}

/* 响应式调整 */
@media (max-width: 600px) {
  #app {
    padding: 0 10px;
  }

  .el-main {
    padding: 60px 0 10px;
  }
}
</style>
<script>
import {OKXTonConnect,
  OKX_CONNECT_ERROR_CODES,
  OKX_TON_CONNECTION_AND_TRANSACTION_EVENT
} from "../../src/index"
// } from "okxconnect";

var TON = new OKXTonConnect({metaData: {
            name:"OKX Connect Demo",
            icon: window.location.href + "favicon.ico"
        }})
window.ton = TON
// var testCM = new ConnectionManager()
// async function testconnect() {
//   await testCM.connect()
// }

import QrcodeVue from 'qrcode.vue'
// import {TonProofService} from "@/ton/ton-proof-service";

export default {
  name: 'App',
  components: {
    QrcodeVue
  },
  data: ()=> {
    return {
      testTopic: '',
      testPublicKey: '',
      testProofPayload: '',
      pendingDeepLink: '-',
      logList: [],
      connectReady: false,
      connectStatusStr: '连接钱包',
      disconnectStatusStr: '断开钱包',
      localPubKey: '',
      localSecKey: '',
      testTransaction:'',
      testTransactionStr:'发送交易',
      walletAddress:'',
      testboc:'',
      ProofPayloadVerify:'',
      showModal: false,
      showVerifyRow: false,
      qrValue: 'https://www.okx.com/download',
      currentStatus:"",
      isChecked:localStorage.getItem("autoRefresh") == "true",
      handleChange:(isChecked) => {
        localStorage.setItem("autoRefresh",`${isChecked}`)
      }
    }
  },
  computed: {
    getWalletAddress:function (){
      return this.walletAddress;
    },


  },
  methods: {
    async connectBtnClicked() {
      console.log(`encryptStoreMsg   connectBtnClicked  ${Date.now()}`);
        this.testTonSdk();

    },
    async disconnectAction() {
      try {
        console.log('get disconnect wallet btn clicked');
        await TON.disconnect();
      } catch (error) {
        console.log('Disconnect  error ==>> ',error.message);
        this.currentStatus = error.message;
      }
    },
    async resotreConnectAction() {
      console.log('get restore wallet btn clicked');
      TON.restoreConnection();
    },
    // clearCacheKeyPair: () => {
    //   console.log(`generate local key pair`)
    //   testCM.cleanCache()
    //   // testTonSdk()
    //
    // },

    async mockSwap(){
      var data =  {"messages":[{"address":"EQARULUYsmJq1RiZ-YiH-IJLcAZUVkVff-KBPwEmmaQGH6aC","amount":"205000000","payload":"te6cckEBAgEAhwABbQ+KfqUAAADNgG7tIEATEtAIAO87mQKicbKgHIk4pSPP4k5xhHqutqYgAB7USnesDnCcECwbgQMBAJUlk4VhgBD3JEg1TUr75iTijBghOKm/sxNDXUBl7CD6WMut0Q85x4RafwA/Es89DBXoTxuqxVFxyBbzt9Rav2HBUKl7hmkvLuKHLBCW57aK"}],"validUntil":1792481054,"network":-239}
      this.testTransaction = JSON.stringify(data)
    },
    async mockTransaction(){
      var data  = {"messages":[{"address":"UQDXlIPZgiC7ugBqdg68ZQ4zBVGaTEfTQd1ywCDB8exACtQK","amount":1100000}],"valid_until":1792481054,"from":"0:a1d59286b6e351035619f42e8ef1b7ff804f6256b57d889b61189b7835ae2541","network":"-239"}
      this.testTransaction = JSON.stringify(data)
    },
    async mockTransactionNFT(){
      var data = {"from":"EQD8Szz0MFehPG6rFUXHIFvO31Fq_YcFQqXuGaS8u4ocsMqN","messages":[{"address":"EQBpDpRrE3b4g01AXoyCQbnUGTpVCCgCBGyTPWvKvwDyQKqZ","amount":"20000000","payload":"te6cckEBAQEAUwAAoV/MPRQAAAAAAAAAAIAa8pB7MEQXd0ANTsHXjKHGYKozSYj6aDuuWAQYPj2IAVAD8Szz0MFehPG6rFUXHIFvO31Fq/YcFQqXuGaS8u4ocsAgKGBCo0E="}],"valid_until":1792481054,"network":"-239"}
      this.testTransaction = JSON.stringify(data)
    },
    async sendTransaction(){
      console.log('sendTransaction')
      console.log('sendTransaction内容------',this.testTransaction)
      if (this.testTransaction) {
        // eslint-disable-next-line no-undef
        const sendData = JSON.parse(this.testTransaction)
        try {
          var result = await TON.sendTransaction(sendData)
          this.testboc = result.boc
        } catch (error) {
          if (error.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR) {
            this.testboc = "用户拒绝交易"
          } else {
            this.testboc = "签名错误！"
          }
        }
      } else {
        alert("交易数据为空，请选则或输入交易数据。")
      }
    },

    async testTonSdk(){
      if (TON.connected){
        console.log('you have connected wallet');
      }else {
        console.log('APP ---connect Proof--->>>>----',this.testProofPayload);
        let connectData = {
          tonProof:this.testProofPayload,
          redirect:"tg://resolve",
          openUniversalLink:true
        };

        if (this.isPC()) {
          connectData.redirect = null;
          connectData.openUniversalLink = false;
          console.log('Is PC, showing connectData',connectData);

          let url = await TON.connect(connectData);
          console.log('Is PC, showing modal ==>',url);
          if (url){
            this.qrValue = url;
            this.showModal = true;
          }

        }else {
          if (this.isSafariOrChrome() && !window?.TelegramWebview && !window?.TelegramWebviewProxy){
            connectData.redirect = null;
          }
          console.log('Is Mobile ==>',connectData);
          await  TON.connect(connectData);
        }
      }

    },

    closeModal() {
      this.showModal = false;
    },

    isPC() {
      const userAgentInfo = navigator.userAgent
      console.log('current device ua is ===>',userAgentInfo)

      const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
      let flag = true
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    },
    isSafariOrChrome() {
      const userAgentInfo = navigator.userAgent
      const Agents = ["Safari", "Chrome"]
      let flag = false
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true
          break
        }
      }
      return flag
    },
  },
  created() {
    console.log("test url", window.location)
    for (const eventName of Object.values(OKX_TON_CONNECTION_AND_TRANSACTION_EVENT)) {
      console.log(eventName);
      window.addEventListener(eventName, (event) => {
        console.log(eventName,event.detail)
        this.currentStatus = eventName
      });
    }
  },

  mounted() {
    this.connectReady = true
    // console.log('test connect called')
    // testconnect()
    // const keyPairInfo = testCM.getCryptoInfo()
    // console.log(keyPairInfo)
    // this.localPubKey = keyPairInfo.kp.publicKey
    // this.localSecKey = keyPairInfo.kp.secretKey
    // this.testPublicKey = this.localPubKey
    // this.testTopic = keyPairInfo.topic?.content

    TON.onStatusChange((walletInfo) => {
      console.log('APP ---TON.onStatusChange>>----', walletInfo);
          if (walletInfo) {
            this.walletAddress = walletInfo.account.address;
            if (this.testProofPayload) {
              var checkProofData = {
                address: this.walletAddress,
                network: walletInfo.account.chain,
                public_key:walletInfo.account.publicKey,
                proof:{
                  ... walletInfo.connectItems.tonProof.proof,
                  state_init: walletInfo.account.walletStateInit
                }
              }
              console.log(`checkProof => ${JSON.stringify(checkProofData)}`)
              // new TonProofService().checkProof(checkProofData).then((result) => {
              //   this.ProofPayloadVerify = `${result}`;
              //   this.showVerifyRow = true;
              // }).catch((error)=>{
              //   this.ProofPayloadVerify = `error ${error}`;
              //   this.showVerifyRow = true;
              // })
            } else {
              this.ProofPayloadVerify = ": no proof";
            }
            this.showModal = false;

          } else {
            this.walletAddress = "disconnected";
          }
        }, (error) => {
          this.showModal = false;
      if (error.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR){
        this.walletAddress = `用户拒绝链接: code(${error.code}) `;
      }else {
        this.walletAddress = `链接报错: code(${error.code})  ${error.message}`;
      }
        }
    )
    if (this.isChecked){
      TON.restoreConnection()
    }
    //
    // TON.onStatusChange(function (walletInfo) {
    //
    //
    // }.bind(this));

  }
}
</script>