"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUrlLastSlash = removeUrlLastSlash;
exports.addPathToUrl = addPathToUrl;
exports.getUniversalLink = getUniversalLink;
exports.openOKXDeeplinkWithFallback = openOKXDeeplinkWithFallback;
exports.getWindow = getWindow;
const version_1 = require("../../ton/tonsdk/constants/version");
function removeUrlLastSlash(url) {
    if (url.slice(-1) === '/') {
        return url.slice(0, -1);
    }
    return url;
}
function addPathToUrl(url, path) {
    return removeUrlLastSlash(url) + '/' + path;
}
function getUniversalLink(deeplinkUrl) {
    const deeplinkEncodeUrl = encodeURIComponent(deeplinkUrl);
    return `${version_1.standardUniversalLink}?deeplink=${deeplinkEncodeUrl}`;
}
function openOKXDeeplinkWithFallback(deeplinkUrl) {
    var _a;
    const fullUrl = getUniversalLink(deeplinkUrl);
    const isTelegram = !!((_a = getWindow()) === null || _a === void 0 ? void 0 : _a.TelegramWebviewProxy);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let deepLinkOpened = false;
    const timeoutDuration = 1500; // 1.5秒的等待时间
    const startTime = Date.now();
    const downloadUrl = version_1.standardUniversalLink;
    if (!isIOS) {
        let newWindow = null;
        var fallbackToDownloadPage = () => {
            if (!deepLinkOpened) {
                if (newWindow) {
                    newWindow.open(downloadUrl, '_self');
                }
                else {
                    window.open(downloadUrl, '_blank');
                }
            }
        };
        if (isTelegram) {
            newWindow = window.open(deeplinkUrl, '_blank');
        }
        else {
            window.location.href = deeplinkUrl;
        }
        setTimeout(fallbackToDownloadPage, timeoutDuration);
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                deepLinkOpened = true;
            }
        });
    }
    else {
        if (isTelegram) {
            window.open(fullUrl, '_blank');
        }
        else {
            window.open(fullUrl, '_self');
        }
    }
}
function getWindow() {
    if (typeof window !== 'undefined') {
        return window;
    }
    return undefined;
}
function openDeeplink(deeplinkUrl) {
    let appOpened = false;
    const maxWaitTime = 5000; // 5秒
    const startTime = Date.now();
    // 尝试打开app
    window.location.href = deeplinkUrl;
    // alert(deeplinkUrl);
    const fullUrl = getUniversalLink(deeplinkUrl);
    function checkReturn() {
        if (document.hidden) {
            // 页面隐藏，可能是app打开了
            appOpened = true;
        }
        else if (appOpened) {
            // 页面重新可见，且之前app已打开，说明用户已返回
            clearInterval(checkInterval);
            return;
        }
        else if (Date.now() - startTime > maxWaitTime) {
            // 超时，app可能没有安装
            clearInterval(checkInterval);
            window.location.href = fullUrl;
        }
    }
    let checkInterval = setInterval(checkReturn, 50);
}
function isBelow16iOS() {
    const userAgent = navigator.userAgent;
    // 检查是否是iOS设备
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
        // 提取iOS版本号
        const match = userAgent.match(/OS (\d+)_/);
        if (match && match[1]) {
            const version = parseInt(match[1], 10);
            return version < 16;
        }
    }
    // 如果不是iOS设备或无法确定版本,返回false
    return false;
}
